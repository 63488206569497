//
// Sweet alert 2
// Sweet alert 2 plugin overrides
//


.swal2-container {
    background: rgba(#0a0a0f, .25) !important;
    backdrop-filter: blur(5px);
}

.swal2-popup {
    background: rgba(#0a0a0f, .9) !important;
    backdrop-filter: blur(5px) !important;
    padding: $swal2-padding !important;
    border-radius: 24px !important;
    border: 1px solid #21262d !important;

    .swal2-title {
        color: #fff;
        font-size: $swal2-title-font-size;
        line-height: 1;
    }

    .swal2-content {
        color: #e7ebf0;
        font-size: $swal2-content-font-size;

        .swal2-input {
            background: #161b22 !important;
            border: 1px solid transparent;
            border-radius: 12px;
            padding: 6px 16px;
            font-size: 22px !important;
            max-height: 48px;
            color: #e7ebf0 !important;
            margin-bottom: 0;

            &::placeholder {
                color: rgba(#fff, 0.25) !important;
            }

            &:focus {
                border-color: rgba(#ffc600, .3);
            }
        }
    }

    .swal2-image {
        max-width: 200px;
    }

    .swal2-confirm {
        min-width: 120px;
        color: #0a0a0f !important;
        background: #ffc600 !important;
        border: 0;
        height: 45px;
        padding: 0 16px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        font-size: 22px;
        -webkit-font-smoothing: subpixel-antialiased;
        backface-visibility: hidden;
        transition: all 0.25s ease;
        will-change: transform;

        span {
            margin-top: 2px;
        }

        &:hover {
            transform: scale(1.02) !important;
            text-shadow: rgba(182, 103, 0, 0.25) 0 2px 6px;
            box-shadow: rgba(#ffc600, 0.1) 0 13px 27px -5px, rgba(#ffc600, 0.3) 0 8px 16px -8px !important;
        }
    }

    .swal2-cancel {
        color: #e7ebf0 !important;
        background: #161b22 !important;
        border: 0;
        height: 45px;
        padding: 0 16px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        font-size: 22px;
        -webkit-font-smoothing: subpixel-antialiased;
        backface-visibility: hidden;
        transition: all 0.25s ease;
        will-change: transform;

        span {
            margin-top: 2px;
        }

        &:hover {
            transform: scale(1.02) !important;
            text-shadow: rgba(182, 103, 0, 0.25) 0 2px 6px;
            box-shadow: rgba(#161b22, 0.1) 0 13px 27px -5px, rgba(#161b22, 0.3) 0 8px 16px -8px !important;
        }
    }

    .swal2-icon.swal2-success .swal2-success-ring {
        border: .25em solid rgba(21, 151, 125, 0.3);
    }

    .swal2-icon.swal2-success [class^=swal2-success-line] {
        background-color: #09d3ab;
    }

    .swal2-actions {
        margin-top: 16px;
    }

    @media (min-width: 768px) {
        width: 400px !important;
    }
}
