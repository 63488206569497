@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Sarabun:wght@400;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css');

* {
	box-shadow: none !important;
}

// body {
// 	font-family: 'Roboto', 'Sarabun', sans-serif !important;
// }

.form-control {
	font-size: 1rem !important;
}
.btn {
	font-weight: normal;
	transform: none !important;
}
.sidenav-toggler-line {
	background-color: #fff !important;
}
.nav-link-text {
	color: #fff !important;
}
.nav-link.active {
	color: #212529 !important;
}
td {
	vertical-align: middle !important;
}
.custom-control-label {
	font-size: 1rem !important;
}
.bg-dark {
	h5,
	span {
		color: #fff !important;
	}
}
.mw-150px {
	min-width: 150px;
}
