$msn-yellow-color: #ffc600;
$msn-black-color: #0a0a0f;
$msn-grey-color: #161b22;
$msn-red-color: #dc3545;
@import 'msn-theme/fontawesome.scss';

@font-face {
    font-family: 'DB HelvethaicaX';
    src: url('./../fonts/db-helvethaica-x/DBHelvethaicaX-55Regular.eot');
    src: url('./../fonts/db-helvethaica-x/DBHelvethaicaX-55Regular.eot?#iefix') format('embedded-opentype'),
    url('./../fonts/db-helvethaica-x/DBHelvethaicaX-55Regular.woff2') format('woff2'),
    url('./../fonts/db-helvethaica-x/DBHelvethaicaX-55Regular.woff') format('woff'),
    url('./../fonts/db-helvethaica-x/DBHelvethaicaX-55Regular.ttf') format('truetype'),
    url('./../fonts/db-helvethaica-x/DBHelvethaicaX-55Regular.svg#DBHelvethaicaX') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'DB HelvethaicaX';
    src: url('./../fonts/db-helvethaica-x/DBHelvethaicaX-65Med.eot');
    src: url('./../fonts/db-helvethaica-x/DBHelvethaicaX-65Med.eot?#iefix') format('embedded-opentype'),
    url('./../fonts/db-helvethaica-x/DBHelvethaicaX-65Med.woff2') format('woff2'),
    url('./../fonts/db-helvethaica-x/DBHelvethaicaX-65Med.woff') format('woff'),
    url('./../fonts/db-helvethaica-x/DBHelvethaicaX-65Med.ttf') format('truetype'),
    url('./../fonts/db-helvethaica-x/DBHelvethaicaX-65Med.svg#DBHelvethaicaX') format('svg');
    font-weight: 500;
    font-style: normal;
}

html, body {
    background: $msn-black-color;
    font-family: 'DB HelvethaicaX', Helvetica, sans-serif !important;
    font-size: 24px;
    line-height: 1;
}

.msn-top-bar {
    position: sticky;
    top: 0;
    background: rgba($msn-black-color, 0.9) !important;
    backdrop-filter: blur(5px);
    z-index: 999;
}

.close-sidebar {
    color: #fff;
    width: 100% !important;
    text-align: right !important;
    padding: 0 30px;
    margin-top: -16px;
}

.navbar-brand {
    font-weight: bold;
    letter-spacing: 2px;
    color: #fff !important;

    span {
        color: $msn-yellow-color !important;
    }
}

.msn-sidebar {
    background: rgba($msn-black-color, 0.9) !important;
    backdrop-filter: blur(5px);
    border-color: rgba($msn-grey-color, 0.9) !important;

    .scrollbar-inner {
        display: flex;
        flex-direction: column;

        .navbar-inner {
            flex: 1;

            .navbar-nav {
                height: 100% !important;

                .nav-item {
                    &:last-child {
                        margin-top: auto !important;
                    }

                    .nav-link {
                        position: relative;
                        -webkit-font-smoothing: subpixel-antialiased;
                        backface-visibility: hidden;
                        font-size: 0.975rem;
                        text-shadow: rgba(182, 103, 0, 0.75) 0 0 0;
                        padding: 8px 36px;
                        line-height: 2;

                        &::before {
                            content: '';
                            position: absolute;
                            top: 4px;
                            left: 16px;
                            right: 16px;
                            bottom: 4px;
                            background-color: $msn-yellow-color;
                            opacity: 0;
                            transition: all 0.25s ease;
                            z-index: -1;
                            border-radius: 16px;
                            box-shadow: rgba($msn-yellow-color, 0.25) 0 0 0 0, rgba($msn-yellow-color, 0.3) 0 0 0 0;
                        }

                        &.msn-active {
                            i, .nav-link-text {
                                color: $msn-yellow-color !important;
                            }
                        }

                        &.msn-bg-danger {
                            &::before {
                                content: '';
                                position: absolute;
                                top: 8px;
                                left: 16px;
                                right: 16px;
                                bottom: 8px;
                                background-color: $msn-red-color;
                                opacity: 0;
                                transition: all 0.25s ease;
                                z-index: -1;
                                border-radius: 16px;
                                box-shadow: rgba($msn-red-color, 0.25) 0 0 0 0, rgba($msn-red-color, 0.3) 0 0 0 0;
                            }
                        }

                        i {
                            min-width: 1rem;
                            margin-right: 1rem;
                            text-align: center;
                            transition: all 0.25s ease;
                        }

                        &:hover {
                            text-shadow: rgba(182, 103, 0, 0.75) 0 2px 6px;

                            &::before {
                                opacity: 1;
                                box-shadow: rgba($msn-yellow-color, 0.25) 0 13px 27px -5px, rgba($msn-yellow-color, 0.3) 0 8px 16px -8px;
                            }

                            .nav-link-text, i {
                                color: rgba($msn-black-color, 0.9) !important;
                                transform: scale(1.05);
                            }

                            &.msn-bg-danger {
                                text-shadow: 0 2px 6px rgb(145, 0, 0);

                                .nav-link-text, i {
                                    color: #fff !important;
                                }

                                &::before {
                                    box-shadow: rgba($msn-red-color, 0.25) 0 13px 27px -5px, rgba($msn-red-color, 0.3) 0 8px 16px -8px;
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}

.msn-card {
    background-color: transparent;

    .card-header {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: transparent;
        padding: 0;

        @media (min-width: 1200px) {
            padding: 8px 36px 30px;
            flex-direction: row;
            align-items: center;
        }
    }
}

.card {
    &:not(.msn-card) {
        border-radius: 24px;
        background: rgba(#fff, 0.04) !important;
        border: 1px solid rgba($msn-grey-color, 0.9) !important;
    }
}

.msn-info {
    padding: 0;

    @media (min-width: 1200px) {
        padding: 0 36px;
    }
}

.msn-table {
    padding: 0;

    @media (min-width: 1200px) {
        padding: 0 36px;
    }

    .table {
        background: $msn-grey-color;
        border-radius: 16px;
        overflow: hidden;

        @media (min-width: 1200px) {
            border-radius: 24px;
        }

        thead {
            background: #10151c;

            th {
                color: #adb5bd;
                border-top: 0;
                font-size: 20px;

                &.w-first-col {
                    width: 160px;
                }
            }
        }

        tbody {
            tr {
                td {
                    color: #e7ebf0;
                    font-size: 22px;

                    h4 {
                        color: $msn-yellow-color;
                    }

                    i {
                        font-size: 20px;
                    }
                }

                &:nth-of-type(2n) {
                    background: rgba($msn-black-color, 0.1);
                }
            }
        }

    }

    .table thead th, .table th, .table td {
        border-color: #21262d;
    }
}


.text-primary {
    color: #0db0fd !important;
}

.form-group {
    margin-bottom: 24px ;

    &.border-top {
        padding-top: 16px;
        margin-top: 16px;
        border-top: 1px solid #21262d !important;
    }

    &.border-bottom {
        margin-bottom: 16px;
        border-bottom: 1px solid #21262d !important;
    }

    .form-control-label {
        color: #adb5bd;
    }
}

.msn-select {
    position: relative;

    select {
        -webkit-appearance: none;
        appearance: none;
    }

    i {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        color: rgba(#fff, .75);
        font-size: 16px;
    }
}

.form-control {
    background: $msn-grey-color !important;
    border: 1px solid transparent;
    border-radius: 12px;
    padding: 6px 16px;
    font-size: 22px !important;
    max-height: 48px;
    color: #e7ebf0 !important;

    &::placeholder {
        color: rgba(#fff, 0.25) !important;
    }

    &:focus {
        border-color: rgba($msn-yellow-color, .3);
    }
}

.btn-primary {
    min-width: 200px;
    color: $msn-black-color !important;
    background: $msn-yellow-color !important;
    border: 0;
    height: 45px;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-size: 22px;
    -webkit-font-smoothing: subpixel-antialiased;
    backface-visibility: hidden;
    transition: all 0.25s ease;
    will-change: transform;

    i {
        margin-right: 16px;
    }

    span {
        margin-top: 2px;
    }

    &:hover {
        transform: scale(1.02) !important;
        text-shadow: rgba(182, 103, 0, 0.25) 0 2px 6px;
        box-shadow: rgba($msn-yellow-color, 0.1) 0 13px 27px -5px, rgba($msn-yellow-color, 0.3) 0 8px 16px -8px !important;
    }
}

.btn-danger {
    &:hover {
        background-color: #b21927;
        border-color: #b21927;
    }
}

.btn-info {
    &:hover {
        background-color: #0a55c2;
        border-color: #0a55c2;
    }
}

.btn-warning {
    background-color: #9c27b0;
    border-color: #9c27b0;

    &:hover, &:active {
        background-color: #7e1c8f !important;
        border-color: #7e1c8f !important;
    }
}

.btn-success {
    background-color: #12b695;
    border-color: #12b695;

    &:hover {
        background-color: #15977d;
        border-color: #15977d;
    }
}


.input-search {
    position: relative;
    color: #fff;

    i {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        font-size: 16px;
        color: #adb5bd;
    }

    input {
        padding-left: 44px;
    }
}

input[type="date"], input[type="month"] {
    &::-webkit-calendar-picker-indicator {
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAfUlEQVRIie1UQQ6AIAxbjc8g/kcfwsfnP+rFA1lGMIJygN6Abm0hQ6QXeKOWt7S1VRAgeZDU1BELMDwluac9YQRUREKlaQWwuSdP7z0Hr/7zN1hzTloJ9EkAAN5+CV7yf+dgCkyBQQXsJJ8iEir/Ik0XNkG0hBfNY0X9iLgAGtNrnNAFgoIAAAAASUVORK5CYII=') center/80% no-repeat;
        color: rgba(0, 0, 0, 0);
        cursor: pointer;
        will-change: transform;
        transition: transform 0.25s ease;

        &:hover {
            transform: scale(1.02);
        }
    }
}

.modal {
    background: rgba($msn-black-color, .25);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);

    &.show {
        display: block;
    }

    .modal-content {
        background: rgba($msn-black-color, 0.9);
        backdrop-filter: blur(5px);
        border: 1px solid rgba($msn-grey-color, 0.9);
        border-radius: 24px;

        .modal-body {
            color: #e7ebf0;

            h1 {
                color: #fff;
            }
        }
    }

    .modal-close {
        cursor: pointer;

        i {
            font-size: 20px;
            transition: color 0.25s ease;
        }

        &:hover {
            color: $msn-yellow-color;
        }
    }
}

.msn-checkbox {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		&:checked {
			~ {
				.checkmark {
					background-color: $msn-yellow-color;

					&:after {
						display: block;
					}
				}
			}
		}
	}

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        background-color: #333942;
        transition: all 0.25s ease;

        &:after {
            content: '';
            position: absolute;
            display: none;
            top: 45%;
            left: 50%;
			width: 6px;
			height: 14px;
			border: solid $msn-black-color;
			border-width: 0 3px 3px 0;
			-webkit-transform: translate(-50%, -50%) rotate(45deg);
			-ms-transform: translate(-50%, -50%) rotate(45deg);
			transform: translate(-50%, -50%) rotate(45deg);
        }
    }
}

.badge {
    font-size: 20px;
    font-weight: 400;
}
.badge-warning {
    color: #ffc600;
    background-color: rgba(#ffc600, 0.1);
}

.badge-danger {
    color: #dc3545;
    background-color: rgba(#dc3545, 0.1);
}

.badge-success {
    color: #01db9b;
    background-color: rgba(#01db9b, 0.1);
}

.text-success {
    color: #01db9b;
}

.text-danger {
    color: #dc3545;
}


.card-info {
    background-color: $msn-grey-color;
    border-radius: 24px;
    padding: 24px;
}

.status-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .doughnut-chart {
        width: 55%;
    }

    .status-info {
        padding-left: 32px;
    }
}

.doughnut-chart {
    position: relative;

    .doughnut-total {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        line-height: 0.85;

        small {
            color: #e7ebf0;
        }

        .title {
            color: #fff;
            font-size: 28px;
        }
    }
}

.status-topic {
    color: #fff;
    margin-bottom: 0;
    line-height: 1.25;
    font-size: 24px;

    @media (min-width: 992px) {
        font-size: 32px;
    }
}

.status-description {
    color: #e7ebf0;
    line-height: 1;
    font-size: 20px;
    margin-bottom: 12px;
}

.status-list {
    display: flex;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 16px;
    }

    .status-symbol  {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 16px;
    }

    .status-title {
        label {
            color: #e7ebf0;
            margin-bottom: 0;
            font-size: 20px;
        }

        h3 {
            font-size: 28px;
            color: #fff;
            margin-bottom: 0;
            line-height: 0.9;

            small {
                color: #e7ebf0;
                font-size: 20px;
            }
        }
    }

    &.online {
        .status-symbol {
            background-color: #01db9b;
        }

        .status-title {
            label {
                color: #01db9b;
            }
        }
    }

    &.offline {
        .status-symbol {
            background-color: #dc3545;
        }

        .status-title {
            label {
                color: #dc3545;
            }
        }
    }
}

.invoice-amount-info {
    p {
        color: #e7ebf0;
        font-size: 24px;
        line-height: 1;
        margin-bottom: 0;

        small {
            color: rgba(#e7ebf0, 0.75);
        }
    }

    h3 {
        color: #ffc600;
        font-size: 32px;
        line-height: 1;
        letter-spacing: 1px;
        margin-bottom: 0;

        span {
            color: rgba(#e7ebf0, 0.5);
            font-size: 26px;
        }
    }

    @media (min-width: 992px) {
        h3 {
            font-size: 48px;

            span {
                color: rgba(#e7ebf0, 0.5);
                font-size: 40px;
            }
        }
    }
}

.feature-bar {
    color: #e7ebf0;
    font-size: 22px;

    .progress {
        height: 12px;

        .progress-bar {
            &.bg-light {
                background-color: #96f !important;
            }
        }
    }

    &:last-child {
        .progress {
            margin-bottom: 0.5rem;
        }
    }
}