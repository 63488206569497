.wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
}
.invoice {
	width: 210mm;
	height: 296mm;
	background: #fff;
	overflow: hidden;

	p {
		margin: 0;
	}
	h2 {
		margin-bottom: 4px;
	}
	h3 {
		margin-bottom: 8px;
		font-size: 16px;
		font-weight: bold;
	}

	address {
		margin-bottom: 0;
	}
	address:not(:last-child) {
		margin-bottom: 10mm;
	}
	address span {
		color: #606060;
	}

	.table {
		strong {
			margin: 0;
		}
		thead {
			background: #333;
			border: 0;
			color: #fff;
		}
		thead strong {
			margin: 0;
		}
		td {
			padding: 0.75rem 1.25rem;
			vertical-align: middle;
		}
		tbody td {
			color: #212529 !important;
		}
	}

	.total td {
		background: transparent !important;
	}
	.total td:nth-child(n + 3) {
		background: #f1f1f5 !important;
	}
	.total-amount td:nth-child(n + 3) {
		background: #ffc600;
	}
}

.top-header {
	background: #000;
	padding: 4mm 10mm;
	color: #fff;
}

.invoice-info {
	padding: 10mm 10mm 0;
}

.logo {
	width: 50mm;
}

.color-yellow {
	color: #ffc600;
}

.btn-bottom {
	margin: 24px 0;
	display: flex;
	width: 100%;
}
.btn-bottom button {
	margin: 0 auto;
}
